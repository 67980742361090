import React, { useState } from 'react';
import QuoteManagement from '../components/QuoteManagement';
import ContentEditor from '../components/ContentEditor';
import SystemConfig from '../components/SystemConfig';
import UserAnalytics from '../components/UserAnalytics';
import LeadManagement from '../components/LeadManagement';
import SimulationSettings from '../components/SimulationSettings';
import { Button } from '../components/ui/button';

const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('analytics');

  const tabs = [
    { id: 'analytics', label: 'Visitor Analytics' },
    { id: 'content', label: 'Content Management' },
    { id: 'quotes', label: 'Quote Management' },
    { id: 'leads', label: 'Lead Management' },
    { id: 'config', label: 'System Configuration' },
    { id: 'simulation', label: 'Simulation Settings' },
  ];

  return (
    <div className="admin-dashboard p-6">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <nav className="admin-nav mb-6 flex flex-wrap">
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            variant={activeTab === tab.id ? 'default' : 'outline'}
            onClick={() => setActiveTab(tab.id)}
            className="mr-2 mb-2"
          >
            {tab.label}
          </Button>
        ))}
      </nav>
      <div className="admin-content bg-white shadow-md rounded-lg p-6">
        {activeTab === 'analytics' && <UserAnalytics />}
        {activeTab === 'content' && <ContentEditor />}
        {activeTab === 'quotes' && <QuoteManagement />}
        {activeTab === 'leads' && <LeadManagement />}
        {activeTab === 'config' && <SystemConfig />}
        {activeTab === 'simulation' && <SimulationSettings />}
      </div>
    </div>
  );
};

export default AdminDashboard;