import React from 'react';
import { SummaryData } from '../pages/SummaryPage';
import { SunIcon, LeafIcon, DollarSignIcon, PercentIcon, CalendarIcon } from 'lucide-react';

interface PDFContentProps {
  summaryData: SummaryData;
}

const PDFContent: React.FC<PDFContentProps> = ({ summaryData }) => {
  return (
    <div className="min-h-[1056px] w-[816px] bg-white p-8 font-sans">
      {/* Header */}
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <SunIcon className="mr-2 h-10 w-10 text-yellow-500" />
          <h1 className="text-2xl font-bold">Solar Project Summary</h1>
        </div>
        <div className="text-right">
          <p className="font-semibold">Generated on: {new Date().toLocaleDateString()}</p>
          <p className="text-sm text-gray-500">Report #: {Math.floor(Math.random() * 100000)}</p>
        </div>
      </div>

      {/* Main content */}
      <div className="grid grid-cols-2 gap-8">
        {/* Left column */}
        <div>
          {/* Project Details */}
          <div className="mb-6 border rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-2">Project Details</h2>
            <dl className="space-y-2">
              <div className="flex justify-between">
                <dt className="font-medium">System Size:</dt>
                <dd>{summaryData.systemSizeKW.toFixed(2)} kW</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium">Annual Production:</dt>
                <dd>{summaryData.annualProductionKWh.toLocaleString()} kWh</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium">Roof Area Used:</dt>
                <dd>{summaryData.usedArea.toFixed(2)} sq m ({summaryData.roofPercentageUsed.toFixed(2)}%)</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium">Number of Panels:</dt>
                <dd>{summaryData.numberOfPanels}</dd>
              </div>
            </dl>
          </div>

          {/* Financial Information */}
          <div className="mb-6 border rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-2">Financial Information</h2>
            <dl className="space-y-2">
              <div className="flex justify-between">
                <dt className="font-medium">Total Cost:</dt>
                <dd>{summaryData.projectCost.toLocaleString()} TL</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium">Estimated Payback Period:</dt>
                <dd>{summaryData.paybackPeriodYears.toFixed(1)} years</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium">Lifetime Savings:</dt>
                <dd>{summaryData.lifetimeSavings.toLocaleString()} TL</dd>
              </div>
            </dl>
          </div>

          {/* Environmental Impact */}
          <div className="border rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-2">Environmental Impact</h2>
            <p className="mb-4">
              Your solar system will offset approximately {summaryData.co2OffsetTons.toFixed(1)} metric tons of CO2 annually, equivalent to:
            </p>
            <ul className="list-inside list-disc space-y-2">
              <li>Planting {(summaryData.co2OffsetTons * 16.5).toFixed(0)} trees</li>
              <li>Taking {(summaryData.co2OffsetTons / 4.6).toFixed(1)} cars off the road</li>
              <li>Saving {(summaryData.co2OffsetTons * 113).toFixed(0)} gallons of gasoline</li>
            </ul>
          </div>
        </div>

        {/* Right column */}
        <div>
          {/* Selected Area Map */}
          <div className="mb-6 border rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-2">Selected Roof Area</h2>
            <div className="aspect-video w-full overflow-hidden rounded-lg bg-gray-200">
              <div className="flex h-full items-center justify-center">
                <span className="text-gray-400">Map of Selected Area</span>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Address: {summaryData.address}
            </p>
          </div>

          {/* Key Benefits */}
          <div className="border rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-2">Key Benefits</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <DollarSignIcon className="mr-2 h-8 w-8 text-green-500" />
                <div>
                  <p className="font-semibold">Save Money</p>
                  <p className="text-sm text-gray-500">Reduce electricity bills</p>
                </div>
              </div>
              <div className="flex items-center">
                <LeafIcon className="mr-2 h-8 w-8 text-green-500" />
                <div>
                  <p className="font-semibold">Go Green</p>
                  <p className="text-sm text-gray-500">Reduce carbon footprint</p>
                </div>
              </div>
              <div className="flex items-center">
                <PercentIcon className="mr-2 h-8 w-8 text-green-500" />
                <div>
                  <p className="font-semibold">Increase Home Value</p>
                  <p className="text-sm text-gray-500">Boost property worth</p>
                </div>
              </div>
              <div className="flex items-center">
                <CalendarIcon className="mr-2 h-8 w-8 text-green-500" />
                <div>
                  <p className="font-semibold">Long-Term Investment</p>
                  <p className="text-sm text-gray-500">25+ years of benefits</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-8 border-t pt-4 text-center text-sm text-gray-500">
        <p>This report is based on estimates and may vary based on actual installation and energy usage.</p>
        <p>For more information, please contact us at info@solarsimulation.com or call 1-800-SOLAR-POWER.</p>
      </div>
    </div>
  );
};

export default PDFContent;