import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DownloadIcon, SendIcon, CarIcon, TreesIcon, FactoryIcon, ArrowLeftIcon} from 'lucide-react';
import jsPDF from 'jspdf';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import PDFContent from '../components/PDFContent';
import { renderToStaticMarkup } from 'react-dom/server';
import { getSummary } from '../api';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export interface SummaryData {
  projectCost: number;
  systemSizeKW: number;
  annualProductionKWh: number;
  monthlyProductionKWh: number;
  roofPercentageUsed: number;
  numberOfPanels: number;
  paybackPeriodYears: number;
  lifetimeProductionKWh: number;
  lifetimeSavings: number;
  co2OffsetTons: number;
  coordinates: number[][];
  address: string;
  usedArea: number;
  totalArea: number;
  productionPerHour: number;
}

const SummaryPage: React.FC = () => {
  const { rooftopId } = useParams<{ rooftopId?: string }>();
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSummaryData = useCallback(async () => {
    if (!isLoading || !rooftopId) return;

    try {
      const data = await getSummary(rooftopId);
      console.log('Received summary data:', data);
      if (data.status === 'success' && data.data) {
        setSummaryData(data.data);
      } else {
        throw new Error(data.message || 'Failed to fetch summary data');
      }
    } catch (error) {
      console.error('Error fetching summary data:', error);
      if (error instanceof Error) {
        alert(`An error occurred: ${error.message}`);
      } else {
        alert('An unknown error occurred. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [rooftopId, isLoading]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  const handleDownloadPDF = async () => {
    if (!summaryData) return;

    try {
      const pdfContent = renderToStaticMarkup(<PDFContent summaryData={summaryData} />);
      const container = document.createElement('div');
      container.innerHTML = pdfContent;
      container.style.width = '816px'; // Set the width to match PDFContent
      document.body.appendChild(container);

      const canvas = await html2canvas(container, {
        scale: 2,
        logging: false,
        useCORS: true,
        allowTaint: true,
        width: 816, // Set the width to match PDFContent
        height: 1056 // Set the height to match PDFContent
      });

      document.body.removeChild(container);

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [816, 1056] // Set the format to match PDFContent dimensions
      });

      pdf.addImage(imgData, 'PNG', 0, 0, 816, 1056);
      pdf.save('solar-project-summary.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('An error occurred while generating the PDF. Please try again.');
    }
  };

  const generateChartData = useCallback(() => {
    if (!summaryData) return null;

    return {
      labels: ['Year 1', 'Year 5', 'Year 10', 'Year 15', 'Year 20', 'Year 25'],
      datasets: [
        {
          label: 'Cumulative Savings (TL)',
          data: [
            summaryData.annualProductionKWh * 1.37,
            summaryData.annualProductionKWh * 1.37 * 5,
            summaryData.annualProductionKWh * 1.37 * 10,
            summaryData.annualProductionKWh * 1.37 * 15,
            summaryData.annualProductionKWh * 1.37 * 20,
            summaryData.lifetimeSavings,
          ],
          backgroundColor: '#4CAF50',
        },
      ],
    };
  }, [summaryData]);

  const chartData = useMemo(() => generateChartData(), [generateChartData]);

  const handleRequestQuote = () => {
    navigate('/quote', { state: { rooftopId } });
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (!summaryData || !chartData) {
    return <div className="flex justify-center items-center h-screen">No data available</div>;
  }

  return (
    <div className="flex min-h-screen flex-col bg-gray-100">
      <header className="bg-white p-4 shadow">
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <h1 className="text-2xl font-bold">Solar Project Summary</h1>
          <Button variant="ghost" size="icon" asChild>
            <Link to="/">
              <ArrowLeftIcon className="h-6 w-6" />
              <span className="sr-only">Back to main page</span>
            </Link>
          </Button>
        </div>
      </header>
      <main className="flex-1 overflow-auto bg-gray-100">
        <div className="container mx-auto px-4 py-8">
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <Card className="lg:col-span-2 bg-white shadow-lg rounded-lg">
              <CardHeader>
                <CardTitle>Project Overview</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4 sm:grid-cols-2">
                  <div>
                    <h3 className="font-semibold">Project Details</h3>
                    <dl className="space-y-1 text-sm">
                      <div className="flex justify-between">
                        <dt>Project Cost:</dt>
                        <dd>{summaryData.projectCost?.toLocaleString() ?? 'N/A'} TL</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Production:</dt>
                        <dd>{summaryData.annualProductionKWh?.toLocaleString() ?? 'N/A'} kWh/year</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Roof Used:</dt>
                        <dd>{summaryData.roofPercentageUsed?.toFixed(2) ?? 'N/A'}%</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Solar Panels:</dt>
                        <dd>{summaryData.numberOfPanels ?? 'N/A'}</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Production per Hour:</dt>
                        <dd>{summaryData.productionPerHour?.toLocaleString() ?? 'N/A'} watts</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h3 className="font-semibold">Environmental Impact</h3>
                    <div className="mt-2 space-y-2">
                      <div className="flex items-center space-x-2">
                        <CarIcon className="h-5 w-5 text-blue-500" />
                        <span className="text-sm">{((summaryData.co2OffsetTons ?? 0) * 2471).toFixed(0)} km travel distance offset</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <FactoryIcon className="h-5 w-5 text-blue-500" />
                        <span className="text-sm">{((summaryData.co2OffsetTons ?? 0) * 1000).toFixed(0)} kg/year CO2 emissions reduced</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <TreesIcon className="h-5 w-5 text-green-500" />
                        <span className="text-sm">Equivalent to planting {((summaryData.co2OffsetTons ?? 0) * 16.5).toFixed(0)} trees</span>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Selected Area</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="aspect-video w-full bg-gray-200">
                  {/* Placeholder for selected area image */}
                  <div className="flex h-full items-center justify-center">
                    <span className="text-sm text-gray-400">Selected Area Image</span>
                  </div>
                </div>
                <p className="mt-2 text-sm">
                  <span className="font-medium">Address:</span> {summaryData.address}
                </p>
              </CardContent>
            </Card>
          </div>
          <Card className="mt-4">
            <CardHeader>
              <CardTitle>Energy Production and Savings</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="aspect-[2/1] w-full">
                {chartData ? (
                  <Bar
                    data={chartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: 'Cumulative Savings (TL)'
                          }
                        }
                      }
                    }}
                  />
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <p>Chart data is not available</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
      <footer className="bg-white p-4 shadow-top">
        <div className="mx-auto flex max-w-7xl justify-between">
          <Button variant="outline" onClick={handleDownloadPDF}>
            <DownloadIcon className="mr-2 h-4 w-4" />
            Download PDF
          </Button>
          <Button onClick={handleRequestQuote}>
            <SendIcon className="mr-2 h-4 w-4" />
            Request a Quote
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default SummaryPage;