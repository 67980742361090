import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { MapContainer, TileLayer, FeatureGroup, LayersControl } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import * as turf from '@turf/turf';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { TrashIcon, UndoIcon, RedoIcon, MagnetIcon, ArrowRightIcon, Locate } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { saveRooftop } from '../api';

const MainPage: React.FC = () => {
  const [selectedArea, setSelectedArea] = useState<any>(null);
  const [searchAddress, setSearchAddress] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const mapRef = useRef<L.Map | null>(null);
  const featureGroupRef = useRef<L.FeatureGroup | null>(null);
  const [history, setHistory] = useState<any[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [areaInSqM, setAreaInSqM] = useState<number | null>(null);
  const [monthlyCost, setMonthlyCost] = useState<string>('');
  const [roofCoverage, setRoofCoverage] = useState<string>('');
  const navigate = useNavigate();
  const [mapCenter, setMapCenter] = useState({ lat: 38.4192, lng: 27.1287 });

  const handleCreated = (e: any) => {
    const { layer } = e;
    const geoJSON = layer.toGeoJSON();
    setSelectedArea(geoJSON);
    addToHistory(geoJSON);
    calculateArea(geoJSON);
  };

  const calculateArea = (geoJSON: any) => {
    const area = turf.area(geoJSON);
    setAreaInSqM(Math.round(area * 100) / 100); // Round to 2 decimal places
  };

  const addToHistory = (layer: any) => {
    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(layer);
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const handleDelete = () => {
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
      setSelectedArea(null);
      setAreaInSqM(null);
      addToHistory(null);
    }
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      updateMap(historyIndex - 1);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      updateMap(historyIndex + 1);
    }
  };

  const updateMap = (index: number) => {
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
      if (history[index]) {
        const layer = L.geoJSON(history[index]);
        featureGroupRef.current.addLayer(layer);
        setSelectedArea(history[index]);
        calculateArea(history[index]);
      } else {
        setSelectedArea(null);
        setAreaInSqM(null);
      }
    }
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchAddress)}&countrycodes=tr&limit=1`);
      const data = await response.json();
      if (data && data.length > 0) {
        const [lat, lon] = [parseFloat(data[0].lat), parseFloat(data[0].lon)];
        if (mapRef.current) {
          mapRef.current.setView([lat, lon], 18);
        }
        setSuggestions([]);
      } else {
        console.error('Address not found');
        alert('Address not found. Please try a different search term.');
      }
    } catch (error) {
      console.error('Error searching address:', error);
      alert('An error occurred while searching for the address. Please try again.');
    }
  };

  const handleSuggestions = async (value: string) => {
    if (value.length > 2) {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}&countrycodes=tr&limit=5`);
        const data = await response.json();
        setSuggestions(data.map((item: any) => item.display_name));
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleNextClick = async () => {
    if (selectedArea && areaInSqM && monthlyCost && roofCoverage) {
      try {
        console.log('Sending request to save rooftop...');
        const requestBody = {
          area: areaInSqM,
          monthlyCost: parseFloat(monthlyCost),
          roofCoverage: roofCoverage,
          geoJSON: selectedArea,
        };

        const result = await saveRooftop(requestBody);

        if (result.status === 'success') {
          console.log('Rooftop saved successfully:', result);
          navigate(`/summary/${result.rooftopId}`);
        } else {
          console.error('Error saving rooftop:', result.message);
          alert('An error occurred while saving the rooftop. Please try again.');
        }
      } catch (error) {
        console.error('Error saving rooftop:', error);
        alert('An error occurred while saving the rooftop. Please try again.');
      }
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const handleFindMe = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const newCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setMapCenter(newCenter);
        if (mapRef.current) {
          mapRef.current.setView([newCenter.lat, newCenter.lng], 18);
        }
      }, (error) => {
        console.error('Error getting location:', error);
        alert('Unable to retrieve your location. Please check your browser settings.');
      });
    } else {
      alert('Geolocation is not supported by your browser');
    }
  };

  return (
    <div className="flex h-screen flex-col">
      <header className="flex items-center justify-between border-b bg-white p-4">
        <h1 className="text-2xl font-bold">Solar Simulation</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-blue-600 hover:underline">Home</Link>
          <Link to="/about" className="text-blue-600 hover:underline">About</Link>
          <Link to="/contact" className="text-blue-600 hover:underline">Contact</Link>
        </nav>
      </header>
      <main className="flex flex-1 overflow-hidden">
        <div className="flex-1 overflow-hidden relative">
          <MapContainer center={mapCenter} zoom={13} style={{ height: '100%', width: '100%' }} ref={mapRef}>
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Satellite">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="OpenStreetMap">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
              </LayersControl.BaseLayer>
            </LayersControl>
            <FeatureGroup ref={featureGroupRef}>
              <EditControl
                position="topright"
                onCreated={handleCreated}
                draw={{
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polyline: false,
                  polygon: true,
                }}
                edit={{ edit: false }}
              />
            </FeatureGroup>
          </MapContainer>
          <button
            onClick={handleFindMe}
            className="absolute bottom-4 left-4 z-[1000] p-2 bg-white text-black rounded flex items-center justify-center shadow-md hover:bg-gray-100"
          >
            <Locate className="mr-2 h-4 w-4" />
            Find Me
          </button>
        </div>
        <aside className="w-80 overflow-y-auto border-l bg-white p-4">
          <div className="mb-6">
            <h2 className="mb-2 text-lg font-semibold">Rooftop Selection Tools</h2>
            <div className="flex flex-wrap gap-2">
              <Button variant="outline" size="icon" onClick={handleDelete}><TrashIcon className="h-4 w-4" /></Button>
              <Button variant="outline" size="icon" onClick={handleUndo} disabled={historyIndex <= 0}><UndoIcon className="h-4 w-4" /></Button>
              <Button variant="outline" size="icon" onClick={handleRedo} disabled={historyIndex >= history.length - 1}><RedoIcon className="h-4 w-4" /></Button>
              <Button variant="outline" size="icon"><MagnetIcon className="h-4 w-4" /></Button>
            </div>
          </div>
          <div className="mb-6">
            <h2 className="mb-2 text-lg font-semibold">Address Search</h2>
            <div className="flex flex-col">
              <div className="flex">
                <input
                  type="text"
                  placeholder="Enter address in Turkey"
                  className="flex-1 p-2 border rounded"
                  value={searchAddress}
                  onChange={(e) => {
                    setSearchAddress(e.target.value);
                    handleSuggestions(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
                <button className="ml-2 p-2 bg-blue-500 text-white rounded" onClick={handleSearch}>Search</button>
              </div>
              {suggestions.length > 0 && (
                <ul className="mt-2 bg-white border rounded">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setSearchAddress(suggestion);
                        setSuggestions([]);
                        handleSearch();
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="mb-6">
            <h2 className="mb-2 text-lg font-semibold">User Input</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="monthly-cost" className="block mb-1 text-sm font-medium text-gray-700">Current Monthly Electricity Cost (TL)</label>
                <input
                  id="monthly-cost"
                  type="number"
                  placeholder="TL"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={monthlyCost}
                  onChange={(e) => setMonthlyCost(e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="roof-coverage">Roof Coverage</Label>
                <Select value={roofCoverage} onValueChange={(value: string) => setRoofCoverage(value)}>
                  <SelectTrigger id="roof-coverage" className="w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                    <SelectValue placeholder="Select coverage" />
                  </SelectTrigger>
                  <SelectContent className="bg-white border border-gray-300 rounded-md shadow-lg">
                    <SelectItem value="full" className="cursor-pointer hover:bg-gray-100">Full Roof</SelectItem>
                    <SelectItem value="partial" className="cursor-pointer hover:bg-gray-100">Just Enough to Cover Bill</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          {selectedArea && (
            <div className="mb-6">
              <h2 className="mb-2 text-lg font-semibold">Summary</h2>
              <div className="rounded-lg bg-gray-100 p-4">
                <p>Selected Area: {areaInSqM} sq m</p>
                <p>Estimated Production: {Math.round(areaInSqM! * 10)} kWh/year</p>
              </div>
            </div>
          )}
          <Button
            className="w-full"
            onClick={handleNextClick}
            disabled={!selectedArea || !areaInSqM || !monthlyCost || !roofCoverage}
          >
            Continue to Summary
            <ArrowRightIcon className="ml-2 h-4 w-4" />
          </Button>
        </aside>
      </main>
    </div>
  );
};

export default MainPage;