const API_BASE_URL = 'https://www.krescep.com/backend/public/api.php';

async function apiCall(endpoint: string, method: string = 'GET', data: any = null) {
  const url = `${API_BASE_URL}${endpoint}`;
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
}

export const login = (username: string, password: string) => {
  return apiCall('?action=login', 'POST', { username, password });
};

export const getSimulationSettings = () => {
  return apiCall('?action=get_simulation_settings');
};

export const saveRooftop = (rooftopData: any) => {
  return apiCall('?action=save_rooftop', 'POST', rooftopData);
};

export const getSummary = (rooftopId: string) => {
  return apiCall(`?action=get_summary&rooftopId=${rooftopId}`);
};

export const submitQuote = (quoteData: any) => {
  return apiCall('?action=submit_quote', 'POST', quoteData);
};

export const getUserProfile = () => {
  return apiCall('?action=get_user_profile');
};

export const updateUserProfile = (profileData: any) => {
  return apiCall('?action=update_user_profile', 'POST', profileData);
};