// frontend/src/components/UserAnalytics.tsx
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const UserAnalytics: React.FC = () => {
  const [visitorData, setVisitorData] = useState<any[]>([]);
  const [detailedVisitorData, setDetailedVisitorData] = useState<any[]>([]);

  useEffect(() => {
    fetchVisitorData();
  }, []);

  const fetchVisitorData = async () => {
    const response = await fetch('/api.php?action=get_visitor_data');
    const data = await response.json();
    if (data.status === 'success') {
      setVisitorData(data.visitorData);
      setDetailedVisitorData(data.detailedVisitorData);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Visitor Analytics</h2>
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Daily Visitors and Pageviews</h3>
        <LineChart width={600} height={300} data={visitorData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="visitors" stroke="#8884d8" />
          <Line yAxisId="right" type="monotone" dataKey="pageviews" stroke="#82ca9d" />
        </LineChart>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">Detailed Visitor Data</h3>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">IP</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Visit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Visits</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unique Pages</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {detailedVisitorData.map((visitor) => (
              <tr key={visitor.ip}>
                <td className="px-6 py-4 whitespace-nowrap">{visitor.ip}</td>
                <td className="px-6 py-4 whitespace-nowrap">{visitor.device_type}</td>
                <td className="px-6 py-4 whitespace-nowrap">{visitor.last_visit}</td>
                <td className="px-6 py-4 whitespace-nowrap">{visitor.total_visits}</td>
                <td className="px-6 py-4 whitespace-nowrap">{visitor.unique_pages}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserAnalytics;