// frontend/src/components/SimulationSettings.tsx
import React, { useState, useEffect } from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Label } from './ui/label';

interface SimulationSetting {
  name: string;
  value: string;
  description: string;
}

const SimulationSettings: React.FC = () => {
  const [settings, setSettings] = useState<SimulationSetting[]>([]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch('https://krescep.com/backend/public/api.php?action=get_simulation_settings');
      const data = await response.json();
      if (data.status === 'success') {
        setSettings(data.settings);
      }
    } catch (error) {
      console.error('Error fetching simulation settings:', error);
    }
  };

  const handleSettingChange = (index: number, value: string) => {
    const newSettings = [...settings];
    newSettings[index].value = value;
    setSettings(newSettings);
  };

  const saveSettings = async () => {
    try {
      const response = await fetch('https://krescep.com/backend/public/api.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'update_simulation_settings', settings }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        alert('Settings updated successfully');
      }
    } catch (error) {
      console.error('Error saving simulation settings:', error);
      alert('Failed to update settings');
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Simulation Settings</h2>
      {settings.map((setting, index) => (
        <div key={setting.name} className="mb-4">
          <Label htmlFor={setting.name} className="block text-sm font-medium text-gray-700">
            {setting.name}
          </Label>
          <Input
            id={setting.name}
            type="text"
            value={setting.value}
            onChange={(e) => handleSettingChange(index, e.target.value)}
            className="mt-1"
          />
          <p className="mt-1 text-sm text-gray-500">{setting.description}</p>
        </div>
      ))}
      <Button onClick={saveSettings}>Save Settings</Button>
    </div>
  );
};

export default SimulationSettings;