import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';

interface Page {
  id: number;
  title: string;
  content: string;
  slug: string;
}

const ContentEditor: React.FC = () => {
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_pages`);
      const data = await response.json();
      if (data.status === 'success') {
        setPages(data.pages);
      } else {
        console.error('Failed to fetch pages:', data.message);
      }
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  const handlePageSelect = (page: Page) => {
    setSelectedPage(page);
    setEditMode(false);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (selectedPage) {
      setSelectedPage({
        ...selectedPage,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSave = async () => {
    if (selectedPage) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=update_page`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedPage),
        });
        const data = await response.json();
        if (data.status === 'success') {
          fetchPages();
          setEditMode(false);
        } else {
          console.error('Failed to update page:', data.message);
        }
      } catch (error) {
        console.error('Error updating page:', error);
      }
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <Card className="md:col-span-1">
        <CardHeader>
          <CardTitle>Pages</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2">
            {pages.map((page) => (
              <li key={page.id}>
                <Button
                  variant={selectedPage?.id === page.id ? 'default' : 'outline'}
                  onClick={() => handlePageSelect(page)}
                  className="w-full text-left"
                >
                  {page.title}
                </Button>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
      {selectedPage && (
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>
              {editMode ? 'Edit Page' : selectedPage.title}
            </CardTitle>
          </CardHeader>
          <CardContent>
            {editMode ? (
              <div className="space-y-4">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <Input
                    id="title"
                    name="title"
                    value={selectedPage.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                    Slug
                  </label>
                  <Input
                    id="slug"
                    name="slug"
                    value={selectedPage.slug}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                    Content
                  </label>
                  <Textarea
                    id="content"
                    name="content"
                    value={selectedPage.content}
                    onChange={handleInputChange}
                    rows={10}
                  />
                </div>
                <Button onClick={handleSave}>Save Changes</Button>
              </div>
            ) : (
              <div>
                <p className="mb-4"><strong>Slug:</strong> {selectedPage.slug}</p>
                <div dangerouslySetInnerHTML={{ __html: selectedPage.content }} />
                <Button onClick={handleEditToggle} className="mt-4">Edit Page</Button>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ContentEditor;