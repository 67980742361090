import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from './ui/Table';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';

interface Quote {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  comments: string;
  rooftopId: string;
  simulationId: string;
  createdAt: string;
  status: string;
}

const QuoteManagement: React.FC = () => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchQuotes();
  }, []);

  const fetchQuotes = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_quotes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const text = await response.text();
      console.log("Raw response:", text);
      const data = JSON.parse(text);
      if (data.status === 'success') {
        setQuotes(data.quotes);
      } else {
        throw new Error(data.message || 'Failed to fetch quotes');
      }
    } catch (error) {
      console.error('Error fetching quotes:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (quoteId: number, newStatus: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=update_quote_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quoteId, status: newStatus }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setQuotes(quotes.map(quote => 
          quote.id === quoteId ? { ...quote, status: newStatus } : quote
        ));
      } else {
        throw new Error(data.message || 'Failed to update quote status');
      }
    } catch (error) {
      console.error('Error updating quote status:', error);
      alert('Failed to update quote status');
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Quote Management</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              <Th>Address</Th>
              <Th>Rooftop ID</Th>
              <Th>Simulation ID</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {quotes.map((quote) => (
              <Tr key={quote.id}>
                <Td>{`${quote.firstName} ${quote.lastName}`}</Td>
                <Td>{quote.email}</Td>
                <Td>{quote.phone}</Td>
                <Td>{quote.address}</Td>
                <Td>{quote.rooftopId}</Td>
                <Td>{quote.simulationId}</Td>
                <Td>
                  <select
                    value={quote.status}
                    onChange={(e) => handleStatusChange(quote.id, e.target.value)}
                  >
                    <option value="new">New</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </Td>
                <Td>{new Date(quote.createdAt).toLocaleString()}</Td>
                <Td>
                  <Button onClick={() => alert(`View details for quote ${quote.id}`)}>
                    View Details
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default QuoteManagement;