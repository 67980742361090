import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';

interface ConfigItem {
  key: string;
  value: string;
}

const SystemConfig: React.FC = () => {
  const [config, setConfig] = useState<ConfigItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_config`);
      if (!response.ok) {
        throw new Error('Failed to fetch configuration');
      }
      const data = await response.json();
      setConfig(data.config);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching configuration:', error);
      setLoading(false);
    }
  };

  const handleConfigChange = (index: number, value: string) => {
    const updatedConfig = [...config];
    updatedConfig[index].value = value;
    setConfig(updatedConfig);
  };

  const handleSaveConfig = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=update_config`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ config }),
      });
      if (!response.ok) {
        throw new Error('Failed to update configuration');
      }
      alert('Configuration updated successfully');
    } catch (error) {
      console.error('Error updating configuration:', error);
      alert('Failed to update configuration');
    }
  };

  if (loading) {
    return <div>Loading configuration...</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>System Configuration</CardTitle>
      </CardHeader>
      <CardContent>
        {config.map((item, index) => (
          <div key={item.key} className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{item.key}</label>
            <Input
              type="text"
              value={item.value}
              onChange={(e) => handleConfigChange(index, e.target.value)}
              className="mt-1"
            />
          </div>
        ))}
        <Button onClick={handleSaveConfig}>Save Configuration</Button>
      </CardContent>
    </Card>
  );
};

export default SystemConfig;