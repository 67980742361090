import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { SendIcon } from 'lucide-react';
import { ArrowLeftIcon } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../components/ui/card';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { Textarea } from '../components/ui/textarea';
import { Button } from '../components/ui/button';
import { CheckCircle2 } from 'lucide-react';
import { submitQuote } from '../api';

interface QuoteFormData {
  firstName: string;
  lastName: string;
  address: string;
  phone: string;
  email: string;
  comments: string;
}

const QuotePage: React.FC = () => {
  const location = useLocation();
  const rooftopId = location.state?.rooftopId;
  const [formData, setFormData] = useState<QuoteFormData>({
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    email: '',
    comments: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await submitQuote({
        ...formData,
        rooftopId: location.state.rooftopId,
        simulationId: location.state.simulationId,
      });

      if (response.status === 'success') {
        console.log('Quote request submitted successfully!');
        setIsSubmitted(true);
        // Scroll to top to show the notification
        window.scrollTo(0, 0);
      } else {
        setError(response.message || 'Failed to submit quote request. Please try again.');
        console.error('Error submitting quote request:', response.message);
      }
    } catch (error) {
      setError('An error occurred while submitting the quote. Please try again.');
      console.error('Error submitting quote:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white p-4 shadow">
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <h1 className="text-2xl font-bold">Request a Quote</h1>
          <Button variant="ghost" size="icon" asChild>
            <Link to={`/summary/${rooftopId}`}>
              <ArrowLeftIcon className="h-6 w-6" />
              <span className="sr-only">Back to summary page</span>
            </Link>
          </Button>
        </div>
      </header>
      <main className="mx-auto max-w-3xl p-6">
        {isSubmitted && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-8" role="alert">
            <div className="flex items-center">
              <CheckCircle2 className="h-6 w-6 mr-2" />
              <p className="font-medium">Your quote was submitted. We will contact you shortly.</p>
            </div>
          </div>
        )}
        {error && <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8" role="alert">
          <p className="font-medium">{error}</p>
        </div>}
        <Card>
          <CardHeader>
            <CardTitle>Personal Information</CardTitle>
            <CardDescription>Please fill out the form below to request a quote for your solar project.</CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label htmlFor="firstName">First Name</Label>
                  <Input id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="lastName">Last Name</Label>
                  <Input id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="address">Address</Label>
                <Input id="address" name="address" value={formData.address} onChange={handleInputChange} required />
              </div>
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label htmlFor="phone">Phone Number</Label>
                  <Input id="phone" name="phone" type="tel" value={formData.phone} onChange={handleInputChange} required />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="email">Email Address</Label>
                  <Input id="email" name="email" type="email" value={formData.email} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="comments">Additional Comments</Label>
                <Textarea id="comments" name="comments" value={formData.comments} onChange={handleInputChange} />
              </div>
            </form>
          </CardContent>
          <CardFooter>
            <Button className="w-full" onClick={handleSubmit} disabled={loading}>
              <SendIcon className="mr-2 h-4 w-4" />
              {loading ? 'Submitting...' : 'Submit Quote Request'}
            </Button>
          </CardFooter>
        </Card>
      </main>
    </div>
  );
};

export default QuotePage;